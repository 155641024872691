import { type HeadFC, type PageProps } from 'gatsby';
import * as React from 'react';
import { useEffect } from 'react';
import { URLS } from '../utils/urls';

const NotFoundPage: React.FC<PageProps> = () => {
  useEffect(() => {
    window.location.replace(URLS.INFCON_2024__HOME);
  }, []);

  return <></>;
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;
